
* {
  padding: 0;
  margin: 0;
}

.products-container{
  text-align: center;
  margin: 15px;
  
}

h1 {
  text-align: center;
}

.px-2 {
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
}


/* .enter-device-number {
  height: 60px;
  width: 240px;
  letter-spacing: 0;
  line-height: normal;
  margin-top: 16px;
  min-height: 18px;
  min-width: 132px;
  border: 10px solid #7420BC;
  border-radius: 40px;
  display: block;
  text-align: center;
  margin:auto;
  
} */

.btn {
  /* height: 60px; */
  margin-top: 50px;
  /* width: 240px; */
  background-color: #FFB242 !important;
  border-radius: 40px !important;
  box-shadow: 10px !important;
  color: white !important;
  border: none !important;
  
  font-weight: 900 !important;
  font-size:medium !important;
  box-shadow: 3px 3px grey;
  
  
  
}

.search-box {
  text-align: center;
  margin-top: 40px;
}

.search-device-container {
  text-align: center;
 

}














/* Styles for Checkout component on the 2nd page.    */

.App {
  text-align: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.purple-font-underlined {
  color: #7420BC;
  font-weight: 2000;
  text-decoration: underline  #7420BC 2px;

}

.purple-font {
  color: #7420BC;

}

.white-font {
  color: white;

}

.plan-div {
  margin-bottom: 30px;
}












